import React, {
  Suspense,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Canvas } from "@react-three/fiber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faMicrophone,
  faPaperPlane,
  faEraser,
  faRedo,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import { Howl } from "howler";
import Settings from './components/Settings'; // Ensure this is correctly imported
import AvatarModel from "./components/AvatarModel";
import "./App.css";
import UserForm from "./components/UserForm";
import Speech from "./components/Speech";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import React Router components

const TTS_API_URL = "https://api.best-ai-chatbots.com/generate_speech";

const App = () => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [userData, setUserData] = useState(null);
  const [text, setText] = useState("");
  const [spokenText, setSpokenText] = useState([]);
  const [currentStreamingText, setCurrentStreamingText] = useState("");
  const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [hasExplanation, setHasExplanation] = useState(false);
  const [displayText, setDisplayText] = useState("");
  //   const [speaking, setSpeaking] = useState(false);
  const [pausedText, setPausedText] = useState("");
  const fileInputRef = useRef(null);
  const streamingTextRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const speechRef = useRef(null);
  const lastPromptRef = useRef("");
  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [file, setFile] = useState(null);

  const startSound = new Howl({
    src: ["recording-start.mp3"],
    onloaderror: (id, error) =>
      console.error("Error loading start sound:", error),
  });

  const endSound = new Howl({
    src: ["recording-end.mp3"],
    onloaderror: (id, error) =>
      console.error("Error loading end sound:", error),
  });

  const handleFormSubmit = (data) => setUserData(data);
  const handleTextChange = (event) => setText(event.target.value);
  const utteranceRef = useRef(null);
  const streamText = useCallback((text, delay = 1) => {
    const cleanedText = cleanOpenAIOutput(text);

    return new Promise((resolve) => {
      let i = 0;
      const intervalId = setInterval(() => {
        setCurrentStreamingText((prev) => prev + cleanedText[i]);
        i++;
        if (i === cleanedText.length) {
          clearInterval(intervalId);
          resolve();
        }
      }, delay);
    });
  }, []);

  const handleSend = async (text, isExplainAgain = false) => {
    console.log("text at start ", text);
    if (
      (!text && !isExplainAgain && !fileInputRef.current.files[0]) ||
      !userData ||
      isStreaming
    )
      return;

    //   setIsStreaming(true);
    setHasExplanation(false);
    const file = fileInputRef.current.files[0];

    // Create a FormData object to hold the form data
    const formData = new FormData();

    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "text/plain",
      ];
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (!allowedFileTypes.includes(file.type)) {
        alert(
          "File type not allowed. Please upload a JPEG, PNG, PDF, or plain text file."
        );
        setIsStreaming(false);
        return;
      }

      if (file.size > maxSizeInBytes) {
        alert(
          `File size exceeds ${maxSizeInMB}MB limit. Please upload a smaller file.`
        );
        setIsStreaming(false);
        return;
      }

      // Append the file to the FormData object
      formData.append("file", file);
    }

    // Append other form data
    console.log("before append", text);
    formData.append(
      "prompt",
      isExplainAgain
        ? `Please explain this again in a different way: ${lastPromptRef.current}`
        : text
    );
    formData.append(
      "userData",
      JSON.stringify({
        student: userData.student,
        email: userData.email,
        age: userData.age,
        subject: userData.subject,
      })
    );

    try {
      // Display user input and file preview on the blackboard in blue color
      setSpokenText((prev) =>
        [
          ...prev,
          { type: "user", text: cleanOpenAIOutput(text), color: "blue" },
          filePreview
            ? {
                type: "user",
                text: (
                  <>
                    <p>{file.name}</p> {/* Display the filename */}
                    <img
                      src={filePreview}
                      alt="Preview"
                      style={{ maxWidth: "100%" }}
                    />{" "}
                    {/* Display the file preview */}
                  </>
                ),
                color: "blue",
              }
            : null,
        ].filter(Boolean)
      );
      setText("");

      const response = await fetch(
        "https://api.best-ai-chatbots.com/stream_assistant",
        // "http://localhost:5000/stream_assistant",

        {
          method: "POST",
          body: formData, // Send formData directly
        }
      );

      if (!response.ok) throw new Error("Failed to send message");

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      //   setCurrentStreamingText('');
      //   let fullResponse = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        console.log("chunk", chunk);
        setCurrentStreamingText(chunk);
        //   fullResponse += cleanOpenAIOutput(chunk);

        //   const cleanedChunk = cleanOpenAIOutput(chunk);
        //   await streamText(cleanedChunk);
      }

      //   const cleanedFullResponse = cleanOpenAIOutput(fullResponse);

      //   if (speechRef.current) {
      //       await speechRef.current.speak(cleanedFullResponse);
      //   }

      //   setSpokenText((prev) => [
      //     ...prev,
      //     { type: "system", text: cleanedFullResponse },
      //   ]);
      //   setCurrentStreamingText('');
      setHasExplanation(true);
    } catch (error) {
      console.error("Error during streaming:", error);
    } finally {
      // Reset the input field, file preview, and file input after sending
      setText("");
      fileInputRef.current.value = null;
      setFilePreview(null);
      setFileType(null);
      setIsStreaming(false);
    }
  };

  useEffect(() => {
    const setVoiceAndSpeak = () => {
      const voices = window.speechSynthesis.getVoices();
      const britishFemaleVoice = voices.find(voice => 
        voice.lang === 'en-GB' && voice.name.includes('Female')
      );
      
      // If no British female voice is found, fallback to any British voice
      const selectedVoice = britishFemaleVoice || voices.find(voice => voice.lang === 'en-GB');
  
      if (selectedVoice && utteranceRef.current) {
        utteranceRef.current.voice = selectedVoice;
      }
      
      // Now speak with the selected voice
      window.speechSynthesis.speak(utteranceRef.current);
    };
  
    const handleVoicesChanged = () => {
      if (currentStreamingText && window.speechSynthesis) {
        const utterance = new SpeechSynthesisUtterance(currentStreamingText);
        utteranceRef.current = utterance; // Store the utterance for later reference
  
        utterance.onstart = () => {
          setIsSpeaking(true);
          setDisplayText("");
        };
  
        utterance.onboundary = (event) => {
          const charIndex = event.charIndex;
          const spokenSoFar = currentStreamingText.substring(0, charIndex);
          setDisplayText(spokenSoFar);
          setPausedText(spokenSoFar); // Update paused text with what has been spoken so far
        };
  
        utterance.onend = () => {
          setIsSpeaking(false);
          setSpokenText((prev) => [
            ...prev,
            { type: "system", text: currentStreamingText },
          ]);
          setCurrentStreamingText("");
          setDisplayText("");
        };
  
        // Set voice and start speaking after voices are loaded
        setVoiceAndSpeak();
      }
    };
  
    // Add event listener for when voices are loaded
    window.speechSynthesis.addEventListener('voiceschanged', handleVoicesChanged);
  
    // Trigger voice load manually in case voices are already available
    if (window.speechSynthesis.getVoices().length > 0) {
      handleVoicesChanged();
    }
  
    // Clean up the event listener when the component is unmounted
    return () => {
      window.speechSynthesis.removeEventListener('voiceschanged', handleVoicesChanged);
    };
  }, [currentStreamingText]);
  
  
  const handleStop = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel(); // Stop speech
      setSpokenText((prev) => [
        ...prev,
        { type: "system", text: pausedText ? pausedText : displayText },
      ]);
      setDisplayText(""); // Clear display text
      setCurrentStreamingText(""); // Clear current streaming text
      setIsSpeaking(false); // Set speaking to false
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  function cleanOpenAIOutput(text) {
    let cleanedText = text.replace(/(\*\*|__)(.*?)\1/g, "$2");
    cleanedText = cleanedText.replace(/\\([()])/g, "$1");
    cleanedText = cleanedText.replace(/undefined$/, "");
    cleanedText = cleanedText.replace(/\\/g, "");
    cleanedText = cleanedText.trim();
    return cleanedText;
  }

  const handleExplainAgain = () => {
    const question = spokenText[spokenText.length - 2].text;
    console.log("firstqqqqqq", question);
    if (hasExplanation) {
      handleSend(question, true);
    }
  };

  const removeFile = () => {
    setFile(null);
    setFilePreview(null);
    setFileType(null);
    fileInputRef.current.value = null;
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "video/mp4",
        "application/pdf",
        "text/plain",
      ];
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (!allowedFileTypes.includes(uploadedFile.type)) {
        alert(
          "File type not allowed. Please upload a JPEG, PNG, MP4, PDF, or plain text file."
        );
        return;
      }

      if (uploadedFile.size > maxSizeInBytes) {
        alert(
          `File size exceeds ${maxSizeInMB}MB limit. Please upload a smaller file.`
        );
        return;
      }

      setFileType(uploadedFile.type);
      setFile(uploadedFile);

      if (
        uploadedFile.type.startsWith("image/") ||
        uploadedFile.type.startsWith("video/")
      ) {
        setFilePreview(uploadedFile.name);

        setFilePreview(URL.createObjectURL(uploadedFile));
      } else {
        setFilePreview(uploadedFile.name);
      }
    }
    setIsRecording(false);
  };

  const startRecording = () => {
    setIsRecording(true);
    startSound.play();
    audioChunksRef.current = [];

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = async () => {
          endSound.play();
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/mp3",
          });
          const file = new File([audioBlob], "recording.mp3", {
            type: "audio/mp3",
          });
          const formData = new FormData();
          formData.append("audio", file);

          try {
            const response = await fetch(
              "https://api.best-ai-chatbots.com/detect_speech",
              // "http://localhost:5000/detect_speech",

              {
                method: "POST",
                body: formData,
              }
            );

            if (!response.ok) throw new Error("Failed to detect speech");

            const data = await response.json();

            if (data) {
              handleSend(data.text);
            }
            // setUserInput(data.text);
          } catch (error) {
            console.error("Error during speech detection:", error);
          } finally {
            setIsRecording(false);
          }
        };

        mediaRecorderRef.current.start();
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setIsRecording(false);
      });
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
  };

  const handleSpeechToText = () => {
    isRecording ? stopRecording() : startRecording();
  };

  const handleClearBoard = () => {
    setSpokenText([]);
    setCurrentStreamingText("");
    setHasExplanation(false);
    lastPromptRef.current = "";
  };

  useEffect(() => {
    const [navigationEntry] = performance.getEntriesByType("navigation");
    if (navigationEntry?.type === "reload") setShowWelcomeMsg(true);
  }, []);

  useEffect(() => {
    if (streamingTextRef.current) {
      streamingTextRef.current.scrollTop =
        streamingTextRef.current.scrollHeight;
    }
  }, [spokenText, currentStreamingText]);

  if (!userData) {
    return <UserForm onSubmit={handleFormSubmit} />;
  }

  return (

<Router>
<div className="app-container">
  
<nav className="navigation">
  <Link to="/" className="nav-link">Home</Link>
  <Link to="/settings" className="nav-link">Settings</Link>
</nav>

  <Routes>
    <Route path="/" element={
      <div className="main-content">
            <div className="app-container">
      <div className="main-content">
        <div className="canvas-wrapper">
          <Canvas>
            <Suspense fallback={null}>
              <ambientLight intensity={0.5} />
              <directionalLight position={[5, 5, 5]} />
              <AvatarModel isSpeaking={isSpeaking} />
            </Suspense>
          </Canvas>
        </div>
        <div className="board">
          <div className="streaming-text" ref={streamingTextRef}>
            {showWelcomeMsg && (
              <h3 className="welcome-msg">
                Welcome to Bela's {userData.subject} Class
              </h3>
            )}
            {spokenText.map((msg, index) => (
              <p
                key={index}
                className={msg.type === "user" ? "user-text" : "system-text"}
                style={{ color: msg.color || "white" }}
              >
                {typeof msg.text === "string"
                  ? msg.text.split("\n").map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : msg.text}
              </p>
            ))}
            {displayText && <p>{displayText}</p>}
            {/* {currentStreamingText && (
                            <p className="system-text streaming">
                                {currentStreamingText.split('\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>
                        )} */}
          </div>
          <button
            id="eraser"
            className="icon-button"
            onClick={handleClearBoard}
          >
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
        <div className="input-bar">
          <input
            type="text"
            placeholder="Type your message here..."
            value={text}
            onChange={handleTextChange}
            className="text-input"
            onKeyPress={(e) => e.key === "Enter" && handleSend(text)}
          />
          <button
            className="icon-button"
            onClick={() => fileInputRef.current.click()}
          >
            <FontAwesomeIcon icon={faPaperclip} />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
            capture="environment" // Enable camera for image capture
          />
          {filePreview && (
            <div className="file-preview-container">
              <div className="file-preview">
                {fileType.startsWith("image/") && (
                  <img src={filePreview} alt="Preview" />
                )}
                {fileType.startsWith("video/") && (
                  <video src={filePreview} controls />
                )}
                {fileType === "application/pdf" && (
                  <p>PDF File: {filePreview}</p>
                )}
                {fileType === "text/plain" && <p>Text File: {filePreview}</p>}
                <button className="remove-button" onClick={removeFile}>
                  x
                </button>
              </div>
            </div>
          )}
          {text || filePreview ? (
            <button className="icon-button" onClick={() => handleSend(text)}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          ) : (
            <button
              className={`icon-button ${isRecording ? "recording" : ""}`}
              onClick={handleSpeechToText}
            >
              <FontAwesomeIcon icon={faMicrophone} />
            </button>
          )}
          <button className="icon-button" onClick={handleClearBoard}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
          <button className="icon-button" onClick={handleStop}>
            <FontAwesomeIcon icon={faStop} />
          </button>
          <button
            className="icon-button"
            onClick={handleExplainAgain}
            disabled={!hasExplanation || isSpeaking}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
        </div>
      </div>
      {/* <Speech ref={speechRef} onSpeak={setIsSpeaking} /> */}
    </div>

      </div>
    } />
          <Route path="/settings" element={<Settings userData={userData} />} />
          </Routes>
</div>
</Router>

  );
};

export default App;
